import React, { useState } from 'react';
import './Navbar.css'; // Import CSS file for styling
import ReportX from '../../images/ReportX.png'
import SearchIcon from '../../images/SearchIcon.png'
function Navbar() {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        // Add your search functionality here
        console.log('Searching for:', searchQuery);
    };

    const handleLoginClick = () => {
        // Add your login functionality here
        console.log('Clicked login button');
    };

    return (
        <div className='box'>
            <nav className="navbar">
                <div className="navbar-content">
                    <div className="navbar-logo">
                        <img src={ReportX} alt="Logo" />
                    </div>
                    <div className="navbar-search">
                        <form onSubmit={handleSearchSubmit}>
                            <input
                                type="text"
                                placeholder="ძებნა"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </form>
                    </div>
                    <div className="navbar-login">
                        <button onClick={handleLoginClick}>ავტორიზაცია</button>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
