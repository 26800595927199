import React, { useState, useEffect} from 'react';
import Tegeta from "../../images/Tegeta.png";
// import Building from "../../Image/Building.png";
import "./Header.css";
import axios from 'axios';
import { useParams } from 'react-router-dom';


function Header(){

  const { idCode } = useParams();
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    fetchCompanyInfo();
  }, [idCode]);


  const fetchCompanyInfo = () => {
    axios.get(`http://127.0.0.1:5000/retrieve_company_info/${idCode}`)
      .then(response => {
        setCompanyInfo(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  return(
    <div >
      <img src={Tegeta} alt='This is main logo' className='Logo'></img>
      {/* <img src={Building} alt='this is main building ' className='Building'></img> */}
      <i class="fa-regular fa-building"></i>
      <h5 className='organisation'>ორგანიზაცია</h5>
        {companyInfo && companyInfo.length > 0 && (
        <>
          <h2 className='Tegetatitle'>{companyInfo[0].OrgNameInReport}</h2>
          <button type="submit" className='Submit'>შედარება</button>
        </>
      )}
      <i class="fa-solid fa-arrows-left-right"></i>
      <i class="fa-solid fa-paperclip"></i>
      <h4 className="links">ბმულის კოპირება</h4>
      <i class="fa-solid fa-plus"></i>
      <h4 className="Saving">შენახვა</h4>
      <i class="fa-regular fa-file"></i>
      <h4 className="Download">ჩამოტვირთვა</h4>
    </div>
  )
}


export default Header;
