import React, { useState } from 'react';
import "./Nav.css";
import speed from '../../images/speed.png';
import doc from '../../images/doc.png';
import document from '../../images/document.png';
import Building from '../../images/Building.png';
import halfdoc from '../../images/halfdoc.png';
import Table from '../Tables/Table';

function Nav() {
    const initialState = [
        doc,
        document,
        speed,
        halfdoc,
        Building
    ];
    const navbarState = [
        "ფინანსური მდგომარეობა",
        "საქმიანობის შედეგები",
        "ფულადი სახსრების მოძრაობა"
    ];

    const [buttons, setButtons] = useState(initialState);
    const [navbar, setNavbar] = useState(navbarState);
    const [visibleState, setVisibleState] = useState({
        navbar: false,
        main: false,
        news: false,
        financial: false,
        similar: false,
    });
    const [activeItem, setActiveItem] = useState("");

    const handleNavbarItemClick = (item) => {
        setActiveItem(item);
    };

    // Handle click for different sections
    const handleClick = (section) => {
        setVisibleState(prevState => ({
            navbar: false,
            main: false,
            news: false,
            financial: false,
            similar: false,
            [section]: !prevState[section],
        }));
    };

    return (
        <div>
            {buttons.map((image, index) => (
                <button key={index} className={`button-${index}`} onClick={() => handleClick('navbar')}>
                    <img src={image} alt={`Button ${index}`} className={`btnImage-${index}`} />
                </button>

            ))}
            {visibleState.navbar && (
                <ul className='nav'>
                    {navbar.map((item, index) => (
                        <li key={index} className='nav-item' onClick={() => handleNavbarItemClick(item)}>
                            {item}
                            {activeItem === item && <hr className='CaseHr' style={{ display: 'block' }} />}
                        </li>
                    ))}
                </ul>
            )}

            {/* Render AnotherComponent if activeItem is set */}
            {activeItem && <Table />}
           
           <h4 className='Main' onClick={() => handleClick('main')}>მთავარი</h4>
            <hr className='FinancialHr2' style={{ display: visibleState.main ? 'block' : 'none' }}></hr>
            <h4 className='News' onClick={() => handleClick('news')}>სიახლეები</h4>
            <hr className='FinancialHr3' style={{ display: visibleState.news ? 'block' : 'none' }}></hr>
            <h4 className='Financial' onClick={() => handleClick('financial')}>ფინანსური მაჩვენებლები</h4>
            <hr className='FinancialHr4' style={{ display: visibleState.financial ? 'block' : 'none' }}></hr>
            <h4 className='FinancialAng' onClick={() => handleClick('navbar')}>ფინანსური ანგარიშგება</h4>
            <hr className='FinancialHr' style={{ display: visibleState.navbar ? 'block' : 'none' }}></hr>
            <h4 className='Similar' onClick={() => handleClick('similar')}>მსგავსი კომპანიები</h4>
            <hr className='FinancialHr5' style={{ display: visibleState.similar ? 'block' : 'none' }}></hr>
           
         
        </div>
    );
}

export default Nav;
