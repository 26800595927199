import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Header from './Components/Header/Header';
import Nav from './Components/Nav/Nav';
import Table from './Components/Tables/Table';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

function App() {
  return (
 
    <Router>
    <Routes>
    <Route path="/company/:idCode" element={
          <>
            <Navbar/>
            <Header />
            <Nav/>
            {/* <Table/> */}
          </>
        } />

    </Routes>

  </Router>
  );
}

export default App;
