// import React, { useState, useEffect } from 'react';
// import './Table.css';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';

// function Table() {
  
//   const { idCode } = useParams();
//   const [dataFromEndpoint1, setDataFromEndpoint1] = useState([]);
//   const [dataFromEndpoint2, setDataFromEndpoint2] = useState([]);

//   useEffect(() => {
//     fetchDataFromFirstEndpoint();
//     fetchDataFromSecondEndpoint();
//   }, [idCode]);

  
//   const fetchDataFromFirstEndpoint = () => {
//     axios.get(`http://127.0.0.1:5000/retrieve_data_endpoint1/${idCode}`)
//       .then(response => {
//         setDataFromEndpoint1(response.data.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }

//   const fetchDataFromSecondEndpoint = () => {
//     axios.get(`http://127.0.0.1:5000/retrieve_data_endpoint2/${idCode}`)
//       .then(response => {
//         setDataFromEndpoint2(response.data.data);
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }

//   return (
//     <div className='table-container'>
//       <table className='tableClass'>
//         <thead>
//           <tr>
//             <th>განახლდა 15 მარ, 2024</th>
//             <th>საანგარიშგებო პერიოდი</th>
//             <th>წინა საანგარიშგებო პერიოდი</th>
//             <th>თანხობრივი ცვლილება</th>
//             <th>პროცენტული ცვლილება</th>
//           </tr>
//         </thead>
//         <tbody>
//           {dataFromEndpoint1.map((item, index) => {
//             const item2 = dataFromEndpoint2[index] || { value_gel: 0 };

//             const value1 = parseFloat(item.value_gel);
//             const value2 = parseFloat(item2.value_gel);

//             let difference = value1 - value2;

//             const rawPercentage = ((value1 - value2) / Math.abs(value2)) * 100;

//             let percentage = "";

//             if (Number.isFinite(rawPercentage)) {
//               percentage = `${rawPercentage.toFixed(2)}%`;
//             }

//             return (
//               <tr key={index}>
//                 <td>{item.LineItemGEO}</td>
//                 <td>{value1}</td>
//                 <td>{value2}</td>
//                 <td>{difference}</td>
//                 <td>{percentage}</td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default Table;

import React, { useState, useEffect } from 'react';
import './Table.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { environment } from '../../environment/environment';
const BASE_URL = environment.baseUrl

function Table() {
  const { idCode } = useParams();
  const [dataFromEndpoint1, setDataFromEndpoint1] = useState([]);
  const [dataFromEndpoint2, setDataFromEndpoint2] = useState([]);
  // Step 1: Add state for dropdown selection
  const [selectedOption, setSelectedOption] = useState('');

  // Fetch data when idCode or selectedOption changes
  useEffect(() => {
    if(selectedOption) {
      fetchDataFromFirstEndpoint();
      fetchDataFromSecondEndpoint();
    }
  }, [idCode, selectedOption]);


  const fetchDataFromFirstEndpoint = () => {
    axios.get(`${BASE_URL}/retrieve_data_endpoint1/${idCode}?year=${selectedOption}`)
      .then(response => {
        setDataFromEndpoint1(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  const fetchDataFromSecondEndpoint = () => {
    axios.get(`${BASE_URL}/retrieve_data_endpoint2/${idCode}?year=${selectedOption}`)
      .then(response => {
        setDataFromEndpoint2(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  // const fetchDataFromFirstEndpoint = () => {
  //   // Modify the endpoint to include selectedOption if necessary
  //   axios.get(`http://127.0.0.1:5000/retrieve_data_endpoint1/${idCode}?option=${selectedOption}`)
  //     .then(response => {
  //       setDataFromEndpoint1(response.data.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // const fetchDataFromSecondEndpoint = () => {
  //   // Modify the endpoint to include selectedOption if necessary
  //   axios.get(`http://127.0.0.1:5000/retrieve_data_endpoint2/${idCode}?option=${selectedOption}`)
  //     .then(response => {
  //       setDataFromEndpoint2(response.data.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // Step 2: Create the dropdown component
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className='table-container'>
      {/* Dropdown for selecting an option */}
      <select className='tableSelect' value={selectedOption} onChange={handleDropdownChange}>
        <option value="">Select an Option</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        {/* Add more options as needed */}
      </select>
      <table className='tableClass'>
      <thead>
        <tr>
           <th>განახლდა 15 მარ, 2024</th>
           <th>საანგარიშგებო პერიოდი</th>
           <th>წინა საანგარიშგებო პერიოდი</th>
            <th>თანხობრივი ცვლილება</th>
           <th>პროცენტული ცვლილება</th>
         </tr>
       </thead>
         <tbody>
           {dataFromEndpoint1.map((item, index) => {
            const item2 = dataFromEndpoint2[index] || { value_gel: 0 };

            const value1 = parseFloat(item.value_gel);
            const value2 = parseFloat(item2.value_gel);

            let difference = value1 - value2;

            const rawPercentage = ((value1 - value2) / Math.abs(value2)) * 100;

            let percentage = "";

            if (Number.isFinite(rawPercentage)) {
              percentage = `${rawPercentage.toFixed(2)}%`;
            }

            return (
              <tr key={index}>
                <td>{item.LineItemGEO}</td>
                <td>{value1}</td>
                <td>{value2}</td>
                <td>{difference}</td>
                <td>{percentage}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
